@font-face {
    font-family: 'Inter';
    src: url('../fonts/InterVariable.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/InterVariable.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/InterVariable.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

html {
    --font-size: 16px;
    font-size: var(--font-size);
    --layer-color: rgba(255, 255, 255, 0.87);
    font-family: "Inter", sans-serif;

    scroll-behavior: smooth;
}

body {
    --main-layout-sidebar-left-width: 15vw;
    --main-layout-center-width: 45vw;
    --main-layout-sidebar-right-width: 20vw;

    --color-bg: #f5f5f5;

    --color-sea: #3dbda6;
    --color-sea2: #1ba38a;
    --color-sea3:  #16CAB9;
    --color-sea-light: rgb(0, 188, 154, 0.2);
    --color-sea-bg: rgba(61, 189, 166, 0.1);
    --color-white: #ffffff;
    --color-white2: rgba(255, 255, 255, 0.87);
    --color-white-modal: rgba(245, 245, 245, 0.6);
    --color-black: #000000;
    --color-black2: rgba(0, 0, 0, 0.87);
    --color-gray: rgba(0, 0, 0, 0.38);
    --color-gray2: rgba(0, 0, 0, 0.04);
    --color-gray3: rgba(0, 0, 0, 0.1);
    --color-gray4: rgba(0, 0, 0, 0.6);

    --color-red: #ef5350;
    --color-orange: #f59300;
    --color-green: #73b230;
    --color-purple: #b25dda;
    --color-blue: #4093f4;

    --workspace-box-shadow: 0px 3.2px 7.2px rgba(0, 0, 0, 0.4), 0px 0.6px 1.8px rgba(0, 0, 0, 0.2);

    /*  FIXME: Switcher */
    --workspace-widget-background: white;
    --easeOutQuart: cubic-bezier(0.25, 1, 0.5, 1);
    --text-primary: rgba(0, 0, 0, 0.87);
    --text-secondary: rgba(0, 0, 0, 0.6);
    --text-third: rgba(0, 0, 0, 0.37);
    --text-inverse: #000000;
    --element-neutral: rgba(65, 65, 65, 0.08);
    --element: rgba(88, 88, 88, 0.2);
    --colors-white: #ffffff;
    --icon-third: rgba(0, 0, 0, 0.37);
    --accent-blue: #4093f4;
    --circle-houses-special: rgba(0, 0, 0, 0.87);
    --li-psychotechnics: #BA68C8;

    margin: 0;
    background-color: var(--color-bg);
    color: var(--color-black);
}

input,
button {
    font-size: inherit;
    font-family: inherit;
}

input::-webkit-input-placeholder {
    color: var(--color-gray);
}

input:-moz-placeholder {
    color: var(--color-gray);
}

input::-moz-placeholder {
    color: var(--color-gray);
}

input:-ms-input-placeholder {
    color: var(--color-gray);
}

input::-ms-input-placeholder {
    color: var(--color-gray);
}

input::placeholder {
    color: var(--color-gray);
}

@media (max-width: 1440px) {
    html {
        --font-size: 14px;
    }
}

@media (-webkit-max-device-pixel-ratio: 1.25) and (max-width: 1600px), (max-width: 1366px) {
    html {
        --font-size: 12px;
    }
}

@media (max-width: 1280px) {
    html {
        --font-size: 10px;
    }
}

@media (-webkit-max-device-pixel-ratio: 1.5) and (max-width: 1366px), (max-width: 1024px) {
    html {
        --font-size: 10px;
    }
}

@media (max-width: 768px) {
    html {
        --font-size: 10px;
    }
}

@media (max-width: 768px) {
    html {
        --font-size: 16px;
    }
    body {
        --main-layout-sidebar-left-width: 100%;
        --main-layout-center-width: 100%;
        --main-layout-sidebar-right-width: 100%;
    }

    input,
    select {
        font-size: 100%;
    }
}

@media (max-width: 768px) {
    #amobutton {
        width: 30px;
        height: 30px;
    }

    .amo-button-holder {
        right: 30px;
        bottom: 30px;
    }
}

